<template>
  <div>
    <v-row dense class="my-0">
      <v-col>
        <v-row dense>
          <v-col cols="1">
            <v-select outlined required v-model="newMatMed.aditivo_matmed" item-value="value" item-text="nome"
              :items="statusOptions" :rules="requiredField">
              <template v-slot:label>
                <span>Aditivo<span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="newMatMed.aditivo_matmed" cols="1">
            <v-text-field outlined required v-model="newMatMed.nr_aditivo" type="number" :rules="nrAditivoRules(newMatMed)">
              <template v-slot:label>
                <span>Nrº do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="newMatMed.aditivo_matmed" cols="2">
            <v-text-field outlined required v-model="newMatMed.data_aditivo" type="date" :rules="dataAditivoRules()">
              <template v-slot:label>
                <span>Data Início do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select  outlined  v-model="tipoSelecionado"  :items="tiposProdutos"  item-value="id"  item-text="nome"  label="Tipo de produto"  @change="filtrarProdutos"  ></v-select>
          </v-col>
          <v-col cols="5">
            <v-autocomplete outlined required v-model="newMatMed.matmed" :items="produtosFiltrados" item-value="id" item-text="nome" :rules="requiredField" :loading="loadingMedicamento" >
              <template v-slot:label>
                <span>Produto <span style="color: red;">*</span></span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="1" v-if="produtoManipulado">
            <v-text-field label="Manipulado" value="Sim" outlined readonly />
          </v-col>
          <v-col cols="2">
            <v-autocomplete outlined v-model="newMatMed.frequencia" :items="frequencias" clearable item-value="id" item-text="nome" required :rules="requiredField"   @change="updateQuantityByFrequency" return-object>
              <template v-slot:label>
                <span>Frequência <span style="color: red;">*</span></span>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="1">
            <vuetify-money outlined label=Preço required v-model="newMatMed.preco" type="number" :rules="requiredField"
              :options="options" :loading="loadingPrecoECodigo">
            </vuetify-money>
          </v-col>
        </v-row>
       <v-row dense v-if="newMatMed.descricao && newMatMed.descricao.trim() !== '' && !produtoManipulado">
          <v-col cols="5">
            <v-text-field
              v-model="newMatMed.descricao"
              outlined
              readonly
              label="Descrição do Produto na Revista Brasíndice / Simpro"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <v-text-field outlined required v-model="newMatMed.data_inicio_matmed" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data Início<span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field outlined required v-model="newMatMed.data_fim_matmed" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data Fim<span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field outlined required v-model="newMatMed.quantidade_matmed" type="number" :rules="[e => e >= 1 || 'A quantidade deve ser no mínimo 1']">
              <template v-slot:label>
                <span>Quantidade <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <vuetify-money outlined label="Valor Total" required v-model="newMatMed.valor_cobranca_final_matmed"
              type="number" :rules="requiredField" :readonly="true" :loading="loadingPrecoECodigo">
            </vuetify-money>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Código" v-model="newMatMed.codigo" outlined  clearable :rules="requiredField" type="number"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col >
        <v-autocomplete dense label="Unidade de Cobrança" :item-text="unidadeText"  v-model="newMatMed.unidade_medida_cobranca" :items="unidade_cobranca"  item-value="id" outlined :loading="isLoadingUnidadeCobranca" return-object :rules="requiredField"/>
      </v-col>
        <v-col >
          <v-autocomplete dense label="Tabela" :item-text="tabelaText" v-model="newMatMed.tabela" :items="tabela"  item-value="id" outlined :rules="requiredField" :loading="isLoadingTabela" return-object/>
        </v-col>
        <v-col >
          <v-autocomplete dense label="Tipo Guia" :item-text="tipoguiaText" v-model="newMatMed.tipoguia" :items="tipoguia"  item-value="id" outlined :rules="requiredField" :loading="isLoadingTipoGuia" return-objec/>
        </v-col>
        <v-col >
          <v-autocomplete dense label="Tipo Despesa" :item-text="despesaText" v-model="newMatMed.despesa" :items="despesa"  item-value="id" outlined :rules="requiredField" :loading="isLoadingDespesa" return-objec/>
        </v-col>
    </v-row>
    <v-row class="justify-end" dense>
      <ConfirmButton color="success" :loading="loading" :onConfirm="onBeforeSubmit" :block="false">
        Salvar
      </ConfirmButton>
    </v-row>
  </div>
</template>

<script>
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import api from "../../../http";

export default {
  name: "GenerateProceduresFormMatMedDieta",
  props: {
    operadoraId: Number,
    modalidade: Object,
    frequencias: Array,
    orcamentoId: Number,
    statusOptions: Array,
    loading: Boolean,
    onSubmit: Function,
    dataInicio: [Date, String],
    dataFim: [Date, String],
  },
  data: () => UtilsFunc.withCRUDUtils({
    validating: false,
    tipoSelecionado: null, // Armazena a escolha do tipo de produto
    tiposProdutos: [ // Opções disponíveis para o usuário
      { id: 1, nome: "Medicamento" },
      { id: 2, nome: "Material" },
      { id: 3, nome: "Dieta" }
    ],
    matmed: [], // Lista completa de produtos
    produtosFiltrados: [], // Lista filtrada com base na escolha
    loadingMedicamento: false,
    isLoadingTabela: false,
    isLoadingDespesa: false,
    isLoadingTipoGuia: false,
    isLoadingUnidadeCobranca: false,
    loadingmatMed: false,
    produtoManipulado: false,
    loadingPrecoECodigo: false,
    aditivo: [],
    maxNrAditivo: null,
    maxDataAditivo: null,
    pacoteId: [],
    newMatMed: {
      matmed: null,
      preco: '',
      data_inicio_matmed: '',
      data_fim_matmed: '',
      quantidade_matmed: '',
      valor_cobranca_final_matmed: '',
      aditivo_matmed: false,
      nr_aditivo: null,
      data_aditivo: null,
      operadora: null,
      tabela: null,
      despesa: null,
      tipoguia: null,
      unidade_medida_cobranca: null,
    },
    valorPadrao: {
      tabela: null,
      tipoguia: null,
      despesa: null,
    },
    isLoading: true,
    nrAditivoRules(newMatMed) {
      if (newMatMed.aditivo === true) {
        return [v => !!v || 'Nrº Aditivo é obrigatório'];
      }
      return [];
    },
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
    regraOperadora:[],
    tabelaPropria: [],
    tabela: [],
    tipoguia: [],
    despesa:[],
    unidade_cobranca: [],
  }),
  methods: {
    async getMatMed() {
      this.loadingMedicamento = true
      try {
        const { data } = await api.get(`matmed/matmeds/`);
        this.matmed = data.filter(matmed => matmed.ativo === true);
        this.filtrarProdutos(); // Aplica o filtro inicial

      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.loadingMedicamento = false;
      }
    },
    filtrarProdutos() {
      if (!this.tipoSelecionado) {
        this.produtosFiltrados = [];
        return;
      }

      this.produtosFiltrados = this.matmed
        .filter(matmed => matmed.tipo.id === this.tipoSelecionado);
        
    },
    async getTabela() {
        this.isLoadingTabela = true
      try {
        const { data } = await api.get('operadoras/tabelas/')
        this.tabela = data.filter(tabela => tabela.ativo)
        // ✅ Define automaticamente a tabela com ID 4 ao carregar
        const tabelaPadrao = this.tabela.find(item => item.id === 4);
        if (tabelaPadrao) {
          this.newMatMed.tabela = tabelaPadrao;
        }
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTabela = false
      }
    },
    async getTipoguia() {
        this.isLoadingTipoGuia = true
      try {
        const { data } = await api.get('operadoras/tipoguia/')
        this.tipoguia = data.filter(tipoguia => tipoguia.ativo)
        const tabelaPadrao = this.tipoguia.find(item => item.id === 5);
        if (tabelaPadrao) {
          this.newMatMed.tipoguia = tabelaPadrao;
        }
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingTipoGuia = false
      }
    },
    async getDespesa() {
        this.isLoadingDespesa = true
      try {
        const { data } = await api.get('operadoras/despesas/')
        this.despesa = data.filter(despesa => despesa.ativo)
        const tabelaPadrao = this.despesa.find(item => item.id === 5);
        if (tabelaPadrao) {
          this.newMatMed.despesa = tabelaPadrao;
        }
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingDespesa = false
      }
    },
    async getUnidadeMedida() {
        this.isLoadingUnidadeCobranca = true
      try {
        const { data } = await api.get('uteis/unidade/')
        this.unidade_cobranca = data.filter(unidade_cobranca => unidade_cobranca.ativo)
        const tabelaPadrao = this.unidade_cobranca.find(item => item.id === 88);
        if (tabelaPadrao) {
          this.newMatMed.unidade_medida_cobranca = tabelaPadrao;
        }
        // console.log(this.unidade_cobranca)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingUnidadeCobranca = false
      }
    },
    async getAditivo() {
      this.loadingmatMed = true
      const orcamentoId = this.orcamentoId;
      try {
        const { data } = await api.get(`atendimentos/aditivo/?sessao=${orcamentoId}`);
        this.aditivo = data;
        this.maxNrAditivo = data.nr_aditivo;
        this.maxDataAditivo = this.formatDate(data.data_aditivo);
        // console.log(this.aditivo); // Debug
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingmatMed = false;
      }
    },
    async getPacote() {
      this.loadingmatMed = true
      const operadora = this.operadoraId
      const modalidade = this.modalidade.id
      try {
        const { data } = await api.get(`operadoras/operadora-pacotes/?operadora=${operadora}`);
        const pacote = data.find(item => item.ativo && item.modalidade.id === modalidade);
        this.pacoteId = pacote ? pacote.id : null;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingmatMed = false;
      }
    },
    async onBeforeSubmit() {
      this.validating = true;
      let isValid = true;
      // Verificar se data_inicio_matmed não é menor que dataInicio
      const dataInicioTaxas = new Date(this.newMatMed.data_inicio_matmed);
      const dataInicio = new Date(this.dataInicio);
      const dataFim = new Date(this.dataFim);

      if (dataInicioTaxas < dataInicio) {
        this.newMatMed.data_inicio_matmed = this.formatDate(this.dataInicio);
      }

      if (dataInicioTaxas > dataFim) {
        isValid = false;
        this.$toast.error('Data de Início não pode ser maior que a Data de Fim.');
      }

      if (this.newMatMed.aditivo_matmed && !this.newMatMed.nr_aditivo) {
        isValid = false;
        this.$toast.error('Por favor, preencha o Nrº do Aditivo para todos os campos onde Aditivo está como Sim.');
      }

      // Verificar se a quantidade é válida (mínimo 1)
      if (this.newMatMed.quantidade_matmed < 1) {
        isValid = false;
        this.$toast.error('A quantidade deve ser no mínimo 1 unidade.');
      }

      if (this.newMatMed.aditivo_matmed) {
        const dataAditivo = new Date(this.newMatMed.data_aditivo);
        if (dataAditivo < dataInicio || dataAditivo > dataFim) {
          isValid = false;
          this.$toast.error('Data Início do Aditivo deve estar entre a Data de Início e a Data de Fim do Orçamento.');
        }
      }

      if (!this.allFieldsValid()) {
        this.$toast.error('Por favor, preencha todos os campos antes de continuar!');
        this.validating = false;
        return;
      }

      if (!isValid) {
        this.validating = false;
        return;
      }
      
      const fields = {

        sessao: this.orcamentoId,
        matmed: this.newMatMed.matmed,
        codigo: this.newMatMed.codigo,
        data_inicio_matmed: this.newMatMed.data_inicio_matmed,
        data_fim_matmed: this.newMatMed.data_fim_matmed,
        quantidade_matmed: this.newMatMed.quantidade_matmed,
        valor_cobranca_matmed: this.newMatMed.preco,
        valor_cobranca_final_matmed: this.newMatMed.valor_cobranca_final_matmed,
        aditivo_matmed: this.newMatMed.aditivo_matmed,
        pacote: this.pacoteId,
        operadora: this.operadoraId,
        tabela: this.newMatMed.tabela.id,
        despesa: this.newMatMed.despesa.id,
        tipoguia: this.newMatMed.tipoguia.id,
        xmlagrupado: 1,
        unidade_medida: this.newMatMed.unidade_medida_cobranca.id,
        frequencia: this.newMatMed.frequencia ? this.newMatMed.frequencia.id : null,
        
      };
      // console.log('Gerando matmed --> ', fields)
      if (this.newMatMed.aditivo_matmed) {
        fields.nr_aditivo = this.newMatMed.nr_aditivo;
        fields.data_aditivo = this.newMatMed.data_aditivo;
      }
      await this.onSubmit(fields);
      this.validating = false;
    },
    allFieldsValid() {
      return this.newMatMed.matmed && this.newMatMed.preco && this.newMatMed.data_inicio_matmed && this.newMatMed.data_fim_matmed && this.newMatMed.quantidade_matmed && this.newMatMed.valor_cobranca_final_matmed !== '';
    },
    calculateDaysBetweenDates(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = Math.abs(end - start);
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    },
    updateQuantityAndTotal() {
      if (this.newMatMed.data_inicio_matmed && this.newMatMed.data_fim_matmed) {
        const days = this.calculateDaysBetweenDates(this.newMatMed.data_inicio_matmed, this.newMatMed.data_fim_matmed);
        this.newMatMed.quantidade_matmed = days;
        this.newMatMed.valor_cobranca_final_matmed = (this.newMatMed.preco * days).toFixed(2);
      }
    },
    dataAditivoRules() {
      return [
        v => !!v || 'Data Início do Aditivo é obrigatória',
        v => new Date(v) <= new Date(this.dataFim) || 'Data Início do Aditivo não pode ser maior que a Data de Fim do Orçamento.',
      ];
    },
    updateTotal() {
      this.newMatMed.valor_cobranca_final_matmed = (this.newMatMed.preco * this.newMatMed.quantidade_matmed).toFixed(2);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1);
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    formatDateHoje(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate());
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    validateAndFormatDate(dateString) {
      const date = new Date(dateString);
      const dataInicio = new Date(this.dataInicio);
      const dataFim = new Date(this.dataFim);

      if (date < dataInicio) {
        return this.formatDate(this.dataInicio);
      } else if (date > dataFim) {
        this.$toast.error('Data de Início não pode ser maior que a Data de Fim.');
        return '';
      } else {
        return this.formatDate(date);
      }
    },
    updateTotalValue() {
      const preco = parseFloat(this.newMatMed.preco) || 0;
      const quantidade = parseFloat(this.newMatMed.quantidade_matmed) || 0;
      this.newMatMed.valor_cobranca_final_matmed = (preco * quantidade).toFixed(2);
    },
    updateQuantityByFrequency() {
      if (!this.newMatMed.frequencia) {
        this.newMatMed.quantidade_matmed = 0;
        return;
      }

      const selectedFrequency = this.newMatMed.frequencia; // ✅ Já temos o objeto completo
      if (!selectedFrequency || !selectedFrequency.fator) {
        this.newMatMed.quantidade_matmed = 0;
        return;
      }

      // Converte as datas corretamente
      const startDate = new Date(this.newMatMed.data_inicio_matmed);
      const endDate = new Date(this.newMatMed.data_fim_matmed);

      if (isNaN(startDate) || isNaN(endDate) || startDate > endDate) {
        this.newMatMed.quantidade_matmed = 0;
        return;
      }

      // Calcula a diferença de dias
      const daysDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

      // Aplica o fator da frequência
      this.newMatMed.quantidade_matmed = Math.max(1, Math.round(daysDifference * selectedFrequency.fator));

      // Atualiza o valor total
      this.updateTotalValue();
    },
    setTabelaPadrao(tipo) {
      if (tipo === 1 || tipo === 3) {
        this.newMatMed.tabela = this.tabela.find(item => item.id === 4) || null;
      } else if (tipo === 2) {
        this.newMatMed.tabela = this.tabela.find(item => item.id === 19) || null;
      }
    },
    // Regras para Precificação
    async getRegraOperadora() {
      this.loadingmatMed = true
      try {
        const { data } = await api.get(`operadoras/tabela_precificacao/`);
        // console.log(data)
        this.regraOperadora = data.filter(matmed => matmed.ativo === true && matmed.operadora.id === this.operadoraId )
        // console.log('Regra Operadora', this.regraOperadora )
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.loadingmatMed = false;
      }
    },
    async getTabelaPropria() {
      this.loadingmatMed = true
      try {
        const { data } = await api.get(`matmed/tabela_propria/`);
        this.tabelaPropria = data.filter(matmed => matmed.ativo && matmed.operadora.id === this.operadoraId)
        // console.log('Tabela Propria',this.tabelaPropria)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.loadingmatMed = false;
      }
    },
    async selecionarProduto(newVal) {
 
    if (!newVal) return;
    this.loadingPrecoECodigo = true;
    this.newMatMed.preco = null;
    this.newMatMed.codigo = null;
    this.newMatMed.descricao = null;
    


    try {
      // ✅ 1️⃣ Buscar os dados do produto
      let { data: produtoData } = await api.get(`matmed/matmeds/?matmed_id=${newVal}`);
      if (Array.isArray(produtoData) && produtoData.length > 0) {
        produtoData = produtoData[0];
      } else {
        this.$toast.error('Nenhum produto encontrado para este ID.');
        return;
      }
      
      this.produtoManipulado = !!produtoData.manipulado;
      // ✅ 2️⃣ Armazenar a descrição do produto, se houver
      this.newMatMed.descricao = produtoData.tabela_medicamento?.descricao || produtoData.tabela_simpro?.descricao || "";

      // ✅ 3️⃣ Verificar se o produto está na Tabela Própria
      const tabelaPropriaProduto = this.tabelaPropria.find(item => item.produto.id === newVal);
      if (tabelaPropriaProduto) {
        this.newMatMed.preco = tabelaPropriaProduto.preco.toFixed(2);
        this.newMatMed.codigo = tabelaPropriaProduto.codigo;
        this.newMatMed.tabela = tabelaPropriaProduto.tabela.id;
        this.newMatMed.tipoguia = tabelaPropriaProduto.tipoguia.id;
        this.newMatMed.despesa = tabelaPropriaProduto.despesa.id;
        return; // Sai da função pois já encontrou a precificação correta
      }

      const tipoProduto = this.tipoSelecionado;
      // console.log(produtoData)
      if (produtoData.manipulado) {
        await this.definirPrecoManipulado(produtoData);
        // console.log('entrou manipulado')
      } else if (tipoProduto === 1) {
        await this.definirPrecoMedicamento(produtoData);
        // console.log('nao entrou manipulado')
      } else if (tipoProduto === 3) {
        await this.definirPrecoDieta(produtoData);
        // console.log('nao entrou manipulado')
      } else if (tipoProduto === 2) {
        await this.definirPrecoMaterial(produtoData);
        // console.log('nao entrou manipulado')
      }

      // 🔁 Seleciona a unidade de cobrança com base no produto
      if (produtoData.unidade_medida_cobranca) {
        const unidadeId = produtoData.unidade_medida_cobranca.id;
        const unidadeSelecionada = this.unidade_cobranca.find(u => u.id === unidadeId);
        this.newMatMed.unidade_medida_cobranca = unidadeSelecionada || 88;
      } else {
        const unidadePadrao = this.unidade_cobranca.find(u => u.id === 88);
        this.newMatMed.unidade_medida_cobranca = unidadePadrao || 88;
      }



    } catch (error) {
      console.error('Erro ao buscar o produto:', error);
    } finally {
      this.loadingPrecoECodigo = false;
    }
    },
    async definirPrecoManipulado(produtoData) {
   
      // const produtoRestritoHospitalar = produtoData.tabela_medicamento?.restrito_hospitalar || false;
      // ✅ Filtrar as regras da operadora e ordenar por prioridade
      let regrasOrdenadas = this.regraOperadora
      .filter(regra => regra.ativo && regra.manipulado )
      .sort((a, b) => a.ordem - b.ordem);
      regrasOrdenadas = regrasOrdenadas[0]
      // console.log('regrasOrdenadas -> ', regrasOrdenadas)

      if (regrasOrdenadas.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada.');
        return;
      }

      let precoBase = parseFloat(produtoData.custo) || 0

      if (!precoBase || precoBase <= 0) {
        this.$toast.error('Nenhum preço válido encontrado.');
        return;
      }
      console.log('regrasOrdenadas --> ', regrasOrdenadas)
      if (regrasOrdenadas.acrescimo) {
        precoBase += (precoBase * regrasOrdenadas.acrescimo) / 100;
      } else if (regrasOrdenadas.desconto) {
        precoBase -= (precoBase * regrasOrdenadas.desconto) / 100;
      }

     this.newMatMed.codigo = produtoData.cod_TUSS 
        || produtoData.codigo 
        || produtoData.id.toString().padStart(10, '0');


      this.newMatMed.preco = precoBase.toFixed(2);
      // this.newMatMed.codigo =  produtoData.id.toString().padStart(10, '0');
      this.newMatMed.tabela = this.tabela.find(t => t.id === regrasOrdenadas.tabela.id) || null;
      this.newMatMed.tipoguia = this.tipoguia.find(t => t.id === regrasOrdenadas.tipoguia.id) || null;
      this.newMatMed.despesa = this.despesa.find(t => t.id === regrasOrdenadas.despesa.id) || null;

      if (!regrasOrdenadas) {
        this.$toast.info('Sem regra. Restaurando padrões.');
        this.restaurarValoresPadrao();
        return;
      }
  

    },
    async definirPrecoMedicamento(produtoData) {
      const produtoRestritoHospitalar = produtoData.tabela_medicamento?.restrito_hospitalar || false;

      // ✅ Filtrar as regras da operadora e ordenar por prioridade
      let regrasOrdenadas = this.regraOperadora
        .filter(regra => regra.ativo)
        .sort((a, b) => a.ordem - b.ordem);

      if (produtoRestritoHospitalar) {
        // Se for restrito hospitalar, usa apenas regras compatíveis
        regrasOrdenadas = regrasOrdenadas.filter(regra => regra.restrito_hospitalar);
      } else {
        // Se NÃO for restrito hospitalar, exclui regras que são apenas para restritos
        regrasOrdenadas = regrasOrdenadas.filter(regra => !regra.restrito_hospitalar);
      }


      if (regrasOrdenadas.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada.');
        return;
      }

      let precoBase = null;
      let regraSelecionada = null;

      for (let regra of regrasOrdenadas) {
        precoBase = regra.pfb
          ? parseFloat(produtoData.tabela_medicamento?.preco_fab_unidade)
          : parseFloat(produtoData.tabela_medicamento?.preco_pmc_unidade);

        if (precoBase && precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!precoBase || precoBase <= 0) {
        this.$toast.error('Nenhum preço válido encontrado.');
        return;
      }
      // console.log(regraSelecionada)
      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      let codigo = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_medicamento?.cod_brasindice?.padStart(10, '0');

      this.newMatMed.preco = precoBase.toFixed(2);
      this.newMatMed.codigo = codigo ? String(codigo) : '';

      this.newMatMed.tabela = this.tabela.find(t => t.id === regraSelecionada.tabela.id) || null;
      this.newMatMed.tipoguia = this.tipoguia.find(t => t.id === regraSelecionada.tipoguia.id) || null;
      this.newMatMed.despesa = this.despesa.find(t => t.id === regraSelecionada.despesa.id) || null;

      if (!regraSelecionada) {
        this.$toast.info('Sem regra. Restaurando padrões.');
        this.restaurarValoresPadrao();
        return;
      }

    },
    async definirPrecoDieta(produtoData) {
      const produtoRestritoHospitalar = produtoData.tabela_medicamento?.restrito_hospitalar || false;

      // ✅ Filtrar as regras da operadora e ordenar por prioridade
      let regrasOrdenadas = this.regraOperadora
        .filter(regra => regra.ativo)
        .sort((a, b) => a.ordem - b.ordem);

      if (produtoRestritoHospitalar) {
        // Se for restrito hospitalar, usa apenas regras compatíveis
        regrasOrdenadas = regrasOrdenadas.filter(regra => regra.restrito_hospitalar);
      } else {
        // Se NÃO for restrito hospitalar, exclui regras que são apenas para restritos
        regrasOrdenadas = regrasOrdenadas.filter(regra => !regra.restrito_hospitalar);
      }


      if (regrasOrdenadas.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada.');
        return;
      }

      let precoBase = null;
      let regraSelecionada = null;

      for (let regra of regrasOrdenadas) {
        precoBase = regra.pfb
          ? parseFloat(produtoData.tabela_medicamento?.preco_fab_unidade)
          : parseFloat(produtoData.tabela_medicamento?.preco_pmc_unidade);

        if (precoBase && precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!precoBase || precoBase <= 0) {
        this.$toast.error('Nenhum preço válido encontrado.');
        return;
      }

      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      let codigo = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_medicamento?.cod_brasindice?.padStart(10, '0');
      // console.log('codigo dieta-->', codigo)
      this.newMatMed.preco = precoBase.toFixed(2);
      this.newMatMed.codigo = codigo ? String(codigo) : '';

      this.newMatMed.tabela = this.tabela.find(t => t.id === regraSelecionada.tabela.id) || null;
      this.newMatMed.tipoguia = this.tipoguia.find(t => t.id === regraSelecionada.tipoguia.id) || null;
      this.newMatMed.despesa = this.despesa.find(t => t.id === regraSelecionada.despesa.id) || null;

      if (!regraSelecionada) {
        this.$toast.info('Sem regra. Restaurando padrões.');
        this.restaurarValoresPadrao();
        return;
      }

    },
    async definirPrecoMaterial(produtoData) {
      if (!produtoData.tabela_simpro) {
        this.$toast.error('Produto não possui preço na tabela SIMPRO.');
        return;
      }

      // ✅ 1️⃣ Verificar se a operadora tem regras ativas para materiais
      let regrasMateriais = this.regraOperadora
        .filter(regra => regra.ativo && regra.tipo_tabela?.id === 1) // Filtra regras para materiais
        .sort((a, b) => a.ordem - b.ordem); // Ordena por prioridade

      if (regrasMateriais.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada para materiais.');
        return;
      }

      // ✅ 2️⃣ Buscar o preço base do produto
      let precoBase = parseFloat(produtoData.tabela_simpro?.preco_fab_unidade) || 0;

      if (precoBase === 0) {
        this.$toast.error('Nenhum preço válido encontrado para Material.');
        return;
      }

      // ✅ 3️⃣ Percorrer as regras da operadora até encontrar uma válida
      let regraSelecionada = null;

      for (let regra of regrasMateriais) {
        if (precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!regraSelecionada) {
        this.$toast.error('Nenhuma regra aplicável encontrada para materiais.');
        return;
      }

      // ✅ 4️⃣ Aplicar acréscimo ou desconto, se houver
      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      // ✅ 5️⃣ Formatar o código do produto garantindo 10 dígitos
      let codigoMaterial = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_simpro?.cod_simpro?.toString().padStart(10, '0');

      // ✅ 6️⃣ Atualizar os valores no formulário
      this.newMatMed.preco = precoBase.toFixed(2);
      this.atualizarCodigo(codigoMaterial); // Garante que sempre terá um código
      this.newMatMed.tabela = this.tabela.find(t => t.id === regraSelecionada.tabela.id) || null;
      this.newMatMed.tipoguia = this.tipoguia.find(t => t.id === regraSelecionada.tipoguia.id) || null;
      this.newMatMed.despesa = this.despesa.find(t => t.id === regraSelecionada.despesa.id) || null;

      if (!regraSelecionada) {
          this.$toast.info('Sem regra. Restaurando padrões.');
          this.restaurarValoresPadrao();
          return;
        }

    },
   
    restaurarValoresPadrao() {
      this.newMatMed.tabela = this.valorPadrao.tabela;
      this.newMatMed.tipoguia = this.valorPadrao.tipoguia;
      this.newMatMed.despesa = this.valorPadrao.despesa;
    }


  },
  computed:{
    tabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome}`;
      }
    },
    unidadeText() {
      return (item) => {
          return `${item.codigo} - ${item.nome}`;
      }
    },
     tipoguiaText() {
      return (item) => {
            return `${item.tipoguia} - ${item.nome}`;
      }
    },
     despesaText() {
      return (item) => {
            return `${item.despesa} - ${item.nome}`;
      }
    },


  },
  components: { ConfirmButton },
  watch: {
    'newMatMed.data_inicio_matmed': function () {
      this.updateQuantityByFrequency();
    },
    'newMatMed.data_fim_matmed': function () {
      this.updateQuantityByFrequency();
    },
    'newMatMed.frequencia': function (newVal) {
      if (typeof newVal === 'number') {
        this.newMatMed.frequencia = this.frequencias.find(f => f.id === newVal) || null;
      }
      this.updateQuantityByFrequency();
    },
    'newMatMed.matmed': function (newVal) {
      this.selecionarProduto(newVal);
    },
    dataFim(newVal) {
      if (newVal) {
        this.newMatMed.data_fim_matmed = newVal;
        this.updateQuantityAndTotal();
      }
    },
    'newMatMed.aditivo_matmed': function (newVal) {
      if (newVal) {
        this.newMatMed.nr_aditivo = this.maxNrAditivo || 1;
        this.newMatMed.data_aditivo = this.maxDataAditivo || new Date().toISOString();
        this.newMatMed.data_inicio_matmed = this.maxDataAditivo || new Date().toISOString()
      } else {
        this.newMatMed.nr_aditivo = null;
        this.newMatMed.data_aditivo = null;
        this.newMatMed.data_inicio_matmed = this.formatDate(new Date().toISOString().substr(0, 10))
      }
    },
    'newMatMed.nr_aditivo': function (newVal) {
      if (newVal != null) {
        if (newVal == this.maxNrAditivo) {
          this.newMatMed.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
          this.newMatMed.data_inicio_matmed = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
        } else {
          const data = this.dataInicio < this.formatDate(new Date().toISOString().substr(0, 10)) ? this.formatDate(new Date().toISOString().substr(0, 10)) : this.dataInicio
          this.newMatMed.data_aditivo = data;
          this.newMatMed.data_inicio_matmed = this.formatDate(new Date().toISOString().substr(0, 10))
        }
      }
    },
    maxNrAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newMatMed.nr_aditivo = newVal;
        this.newMatMed.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString());
      }
    },
    maxDataAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newMatMed.data_aditivo = newVal;
      }
    },
    'newMatMed.preco': function () {
    this.updateTotalValue();
    },
    'newMatMed.quantidade_matmed': function () {
      this.updateTotalValue();
    },
     tipoSelecionado(newTipo) {
      this.filtrarProdutos();
      // this.newMatMed.matmed = null; // Limpa o produto selecionado ao mudar o tipo

      // ✅ Define a tabela automaticamente com base no tipo de produto
      if (newTipo === 1 || newTipo === 3 ) {
        this.newMatMed.tabela = this.tabela.find(item => item.id === 4) || null;
        this.newMatMed.tipoguia = this.tipoguia.find(item => item.id === 5) || null;
        this.newMatMed.despesa = this.despesa.find(item => item.id === 5) || null;
      } else if (newTipo === 2) {
        this.newMatMed.tabela = this.tabela.find(item => item.id === 3) || null;
        this.newMatMed.tipoguia = this.tipoguia.find(item => item.id === 5) || null;
        this.newMatMed.despesa = this.despesa.find(item => item.id === 5) || null;
      }
    },
  },
  mounted() {
    this.getRegraOperadora()
    this.getTabelaPropria()
    this.getTabela()
    this.getTipoguia()
    this.getDespesa()
    this.getUnidadeMedida()
    this.getMatMed()
      .then(() => this.getAditivo())
      .then(() => this.getPacote())
      .finally(() => {
        const hoje = new Date();
        const dataInicio = new Date(this.dataInicio);
        const dataFim = new Date(this.dataFim)

        if (hoje < dataInicio) {
          this.newMatMed.data_inicio_matmed = this.formatDate(this.dataInicio);
          this.newMatMed.data_fim_matmed = this.formatDate(this.dataInicio);
        } else if (hoje > dataFim) {
          this.newMatMed.data_inicio_matmed = this.formatDate(this.dataFim);
          this.newMatMed.data_fim_matmed = this.formatDate(this.dataFim);
        } else {
          this.newMatMed.data_inicio_matmed = this.formatDateHoje(hoje);
          this.newMatMed.data_fim_matmed = this.formatDateHoje(hoje);
        }
        this.isLoading = false; // Definir isLoading como false após todas as requisições
        // ✅ Adicionando a seleção automática da tabela com id 4
        
    });
    // 🟢 Armazena os valores padrão depois de tudo carregado
      this.valorPadrao.tabela = this.newMatMed.tabela;
      this.valorPadrao.tipoguia = this.newMatMed.tipoguia;
      this.valorPadrao.despesa = this.newMatMed.despesa;

  }
}
</script>
